import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { groupBy, range } from 'lodash';
import { DeviceType } from 'models/device.enums';

import { Checkbox } from '@components/atoms/Checkbox';
import { Input } from '@components/atoms/Input';
import Select from '@components/atoms/Select';
import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { ReactComponent as ChargerIcon } from '@images/icons/Charger.svg';
import brandVoolIcon from '@images/icons/DeviceBrand/Device_VOOL.svg';
import type2Icon from '@images/icons/DevicePlugType/Type2.svg';
import { ReactComponent as PlusIcon } from '@images/icons/Plus.svg';
import { ReactComponent as SolarIcon } from '@images/icons/Solar.svg';
import { useAddDeviceMutation, useCheckDeviceMutation } from '@services/devices/endpoints';
import { selectLmcMultipoints, selectLmcsBySiteUuid } from '@services/devices/selectors';
import { useGetInverterAccountLoginUrlMutation } from '@services/inverters/endpoints';
import { addToastMessage } from '@services/toastMessages';
import { useConnectorTypes } from '@views/Chargers/chargerConst';
import {
  isMainLmc,
  isMultipoint,
} from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

import DeviceTypeOption from './DeviceTypeOption';

const AddDeviceSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { site, showOcppConfigInfoModalForDevice } = useOutletContext();

  const lmcDevices = useSelector((state) => selectLmcsBySiteUuid(state, site?.uuid));
  const rawLmcMultipoints = useSelector(selectLmcMultipoints);
  const connectorTypes = useConnectorTypes();

  const lmcMultipointsOptions = useMemo(
    () =>
      lmcDevices.flatMap((lmc) => {
        const lmcMultipoints = rawLmcMultipoints.filter((mp) => mp.lmcDeviceUuid === lmc.uuid);
        const lmcName = lmc.name || lmc.serialNumber;
        return lmcMultipoints.map((mp) => ({
          label: !isMultipoint(mp) ? lmcName : mp.name,
          value: mp.uuid,
          isMainLmc: isMainLmc(lmc) && !isMultipoint(mp),
        }));
      }),
    [lmcDevices, rawLmcMultipoints],
  );

  const [manufacturer, setManufacturer] = useState(null);
  const [name, setName] = useState('');
  const [overallMaxPowerKw, setOverallMaxPowerKw] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [pin, setPin] = useState('');
  const [currentLimit, setCurrentLimit] = useState('');
  const [lmcMultipointUuid, setLmcMultipointUuid] = useState(null);
  const [numConnectors, setNumConnectors] = useState(1);
  const [connectorsTypeData, setConnectorsTypeData] = useState({});

  const [checkDevice] = useCheckDeviceMutation();
  const [deviceType, setDeviceType] = useState(null);
  const [addDevice, { isLoading }] = useAddDeviceMutation();
  const [updateFailed, setUpdateFailed] = useState(false);
  const [isVoolDeviceNoMatchError, setIsVoolDeviceNoMatchError] = useState(false);
  const [isAddAnother, setIsAddAnother] = useState(false);

  const [isLoadingSolar, setIsLoadingSolar] = useState(false);

  const isOtherDevice = manufacturer === 'other';
  const isCharger = deviceType === DeviceType.CHARGER;

  const manufacturerOptions = [
    {
      label: `VOOL ${t('device', 'Device').toLowerCase()}`,
      value: 'vool',
      description: t('addVoolDeviceToSystem', 'Add VOOL device to system.'),
      Icon: () => <ChargerIcon className="[&_path]:stroke-vermillion" />,
    },
    {
      label: t('otherCharger', 'Other charger'),
      value: 'other',
      description: t('addAnyOcppCharger', 'Add any OCPP compatible charger.'),
      Icon: () => <PlusIcon className="[&_path]:fill-vermillion" />,
    },
    {
      label: t('solarInverter', 'Solar inverter'),
      value: 'solar',
      description: t('addSolarInverterToSystem', 'Add solar inverter to system.'),
      beta: true,
      Icon: () => <SolarIcon className="[&_path]:fill-vermillion" />,
    },
  ];

  const numConnectorsOptions = range(1, 5).map((value) => ({ label: `${value}`, value }));

  const connectorTypeOptions = Object.entries(
    groupBy(
      Object.entries(connectorTypes)
        .map(([connectorType, connector]) => ({
          type: connectorType,
          Icon: connector.icon,
          ...connector,
        }))
        .filter(({ powerType }) => !!powerType),
      'powerType',
    ),
  )
    .map(([powerType, connectorsData]) => [
      { sectionType: 'sectionHeader', label: powerType, value: powerType },
      ...connectorsData.map((connector) =>
        connector.options
          ? [
              { sectionType: 'sectionGroup', label: `${connector.label}:`, value: connector.label },
              ...connector.options.map((option) => ({
                optionLabelOverride: option.label,
                label: connector.label,
                value: `{"connectorType":"${connector.type}","connectorFormat":"${option.connectorFormat}","powerType":"${powerType}"}`,
                Icon: option.icon,
                sectionType: 'sectionOption',
              })),
            ]
          : {
              ...connector,
              value: `{"connectorType":"${connector.type}","connectorFormat":"${connector.connectorFormat}","powerType":"${powerType}"}`,
            },
      ),
    ])
    .flat(2);

  const isRequiredInfoEntered =
    manufacturer &&
    ((isOtherDevice &&
      overallMaxPowerKw &&
      numConnectors === Object.keys(connectorsTypeData).length &&
      Object.values(connectorsTypeData).every((data) => !!data.connectorTypeData && !!data.maxPowerKw)) ||
      (serialNumber && pin));

  const handleCheckDevice = async () => {
    if (isOtherDevice || !serialNumber.trim()) {
      setDeviceType(null);
      return;
    }
    const response = await checkDevice({ number: serialNumber });
    const isAvailable = response.data?.result;
    if (isAvailable) {
      setDeviceType(response.data?.type);
    } else {
      setDeviceType(null);
      setIsVoolDeviceNoMatchError(true);
    }
  };

  const [getInverterAccountLoginUrl] = useGetInverterAccountLoginUrlMutation();

  useEffect(() => setDeviceType(null), [manufacturer, serialNumber]);
  useEffect(() => setIsVoolDeviceNoMatchError(false), [manufacturer, serialNumber, pin]);

  const getVoolDeviceTypeLabel = () => {
    const deviceTypeToLabel = {
      charger: `VOOL ${t('charger', 'Charger')}`,
      lmc: 'VOOL LMC',
    };
    return deviceTypeToLabel[deviceType] ?? t('unknown', 'Unknown');
  };

  const resetForm = () => {
    setName('');
    setSerialNumber('');
    setPin('');
  };

  const saveValues = async () => {
    const response = await addDevice({
      isOtherDevice,
      name: name?.trim() || null,
      ...(isOtherDevice
        ? {
            configOfConnectors: Object.values(connectorsTypeData).map(({ connectorTypeData, maxPowerKw }) => ({
              ...JSON.parse(connectorTypeData),
              maxPowerKw,
            })),
            maxPowerKw: Number(overallMaxPowerKw) || null,
          }
        : { number: serialNumber, pin, ...(currentLimit && { gridConnection: Number(currentLimit) }) }),
      ...(!!lmcMultipointUuid && { lmcMultipointUuid }),
      siteUuid: site?.uuid,
    });
    if (response.error?.data?.message?.toLowerCase().includes('pin')) {
      setIsVoolDeviceNoMatchError(true);
    }
    setUpdateFailed(!!response.error);
    if (isOtherDevice && response?.data?.device) {
      setTimeout(() => showOcppConfigInfoModalForDevice(response.data.device), 400);
    }

    const toastMessage = response?.error
      ? {
          type: 'error',
          title: t('addingDeviceFailed', 'Adding the device failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }
      : {
          type: 'success',
          title: t('deviceAdded', 'Device added'),
          message: t('youCanSeeTheDeviceInTheList', 'You can see the device in your devices list.'),
        };

    dispatch(addToastMessage(toastMessage));
    if (!response.error) {
      resetForm();
    }

    return !response.error && !isAddAnother;
  };

  const handleOptionClick = async (value) => {
    if (value === 'solar') {
      setManufacturer(null);

      try {
        setIsLoadingSolar(true);
        const inverterAccountLoginUrl = await getInverterAccountLoginUrl({ siteUuid: site?.uuid });
        if (inverterAccountLoginUrl.error) {
          throw new Error(
            inverterAccountLoginUrl.error.data?.message || t('somethingWentWrong', 'Something went wrong'),
          );
        }
        window.location.href = inverterAccountLoginUrl.data;
      } catch (e) {
        setIsLoadingSolar(false);
      }
    } else {
      setManufacturer(value);
    }
  };

  useEffect(() => {
    if (lmcMultipointsOptions.length && isCharger) {
      const mainLmcMultipoint = lmcMultipointsOptions.find((lmcMp) => lmcMp.isMainLmc) || lmcMultipointsOptions[0];
      setLmcMultipointUuid(mainLmcMultipoint.value);
    } else {
      setLmcMultipointUuid(null);
    }
  }, [lmcMultipointsOptions, isCharger]);

  return (
    <EditingSidebarBase
      title={
        manufacturer
          ? manufacturerOptions.find((mo) => mo.value === manufacturer)?.label
          : t('addDevice', 'Add a device')
      }
      saveLabel={t('add', 'Add')}
      discardLabel={t('cancel', 'Cancel')}
      containerClassName="flex flex-col"
      containerBottomPadding="pb-36"
      saveDisabled={!isRequiredInfoEntered}
      updateLoading={isLoading}
      updateFailed={updateFailed}
      onSaveValues={saveValues}
      onBackClick={manufacturer && (() => setManufacturer(null))}
      anyDataChanged={!!manufacturer}
      noActions={!manufacturer}
      footer={() =>
        !!manufacturer && (
          <div className="py-4">
            <Checkbox
              name="addAnotherDevice"
              label={t('addAnotherDevice', 'Add another device after this one')}
              onChange={(e) => setIsAddAnother(e.target.checked)}
              checked={isAddAnother}
            />
          </div>
        )
      }
    >
      {!manufacturer && (
        <div className="flex flex-col gap-3">
          {manufacturerOptions.map((m) => (
            <DeviceTypeOption
              key={m.value}
              label={m.label}
              description={m.description}
              Icon={m.Icon}
              onClick={() => handleOptionClick(m.value)}
              isLoading={m.value === 'solar' && isLoadingSolar}
              beta={m.beta}
            />
          ))}
        </div>
      )}
      {isOtherDevice && (
        <div className="mt-6 font-poppins text-gray-600">
          {t(
            'addOtherDeviceText',
            'Here you can add a new charger not manufactured by VOOL. Make sure to configure your device to connect to VOOL servers after saving.',
          )}
        </div>
      )}
      <div className="mt-6 space-y-1 font-poppins">
        {!!manufacturer && (
          <Input
            label={`${t('deviceName', 'Device name')} (${t('optional', 'Optional').toLowerCase()})`}
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        )}
        {manufacturer === 'vool' && (
          <>
            <Input
              label={t('serialNumber', 'Serial number')}
              name="serialNumber"
              value={serialNumber}
              error={isVoolDeviceNoMatchError}
              onChange={(e) => setSerialNumber(e.target.value)}
              inputProps={{ onBlur: handleCheckDevice }}
            />
            <Input
              label={t('pin', 'PIN')}
              name="pin"
              value={pin}
              error={isVoolDeviceNoMatchError}
              onChange={(e) => setPin(e.target.value)}
            />
            {isCharger && (
              <Input
                label={`${('currentLimit', 'Current limit')} ${t('optional', 'Optional').toLowerCase()}`}
                name="currentLimit"
                trailingText="A"
                isInteger
                value={currentLimit}
                error={isVoolDeviceNoMatchError}
                onChange={(e) => setCurrentLimit(e.target.value)}
              />
            )}
            {!!lmcMultipointsOptions.length && isCharger && (
              <Select
                label={t('controller/group', 'Controller/Group')}
                required
                options={lmcMultipointsOptions}
                value={lmcMultipointUuid}
                onChange={(m) => setLmcMultipointUuid(m)}
              />
            )}
            {isVoolDeviceNoMatchError && (
              <p className="mt-4 text-vermillion">
                {t(
                  'voolDeviceNoMatchError',
                  'The entered serial number and/or PIN don’t match with a VOOL device. Please check for any typos.',
                )}
              </p>
            )}
          </>
        )}
        {isOtherDevice && (
          <>
            <Input
              label={t('overallMaxPower', 'Overall max power')}
              name="overallMaxPower"
              trailingText="kW"
              type="number"
              value={overallMaxPowerKw}
              onChange={(e) => {
                const { value } = e.target;
                setOverallMaxPowerKw(value);
                if (numConnectors === 1) {
                  setConnectorsTypeData({
                    ...connectorsTypeData,
                    0: {
                      ...connectorsTypeData[0],
                      maxPowerKw: Number(value),
                    },
                  });
                }
              }}
            />
            <Select
              label={t('numberOfConnectors', 'Number of connectors')}
              options={numConnectorsOptions}
              value={numConnectors}
              required
              onChange={(m) => {
                setNumConnectors(m);
                setConnectorsTypeData(
                  Object.fromEntries(Object.entries(connectorsTypeData).filter(([key]) => Number(key) < m)) ?? {},
                );
              }}
            />

            <p className="pb-2 pt-7 font-semibold">{t('connectorConfiguration', 'Connector configuration')}</p>
            {range(1, numConnectors + 1).map((connectorId, index) => (
              <div key={connectorId} className="flex w-full items-center gap-x-2">
                <div className="w-3 pr-3 pt-5">{`${connectorId}.`}</div>
                <Select
                  label={t('connectorType', 'Connector type')}
                  options={connectorTypeOptions}
                  value={
                    connectorTypeOptions.find((option) => option.value === connectorsTypeData[index]?.connectorTypeData)
                      ?.value
                  }
                  required
                  onChange={(m) =>
                    setConnectorsTypeData({
                      ...connectorsTypeData,
                      [index]: { ...connectorsTypeData[index], connectorTypeData: m },
                    })
                  }
                />
                {numConnectors > 1 && (
                  <Input
                    className="h-[42px] max-w-[180px]"
                    label={t('maxPower', 'Max power')}
                    name="maxPower"
                    trailingText="kW"
                    type="number"
                    onChange={(e) =>
                      setConnectorsTypeData({
                        ...connectorsTypeData,
                        [index]: {
                          ...connectorsTypeData[index],
                          maxPowerKw: Number(e.target.value),
                        },
                      })
                    }
                  />
                )}
              </div>
            ))}
          </>
        )}
      </div>
      <div className="flex-1" />
      {deviceType && (
        <div className="flex flex-col gap-y-4">
          {!lmcMultipointsOptions.length && !isCharger && (
            <div className="font-poppins text-sm leading-6 text-gray-600">
              {t(
                'addFirstLmcText',
                'As this is the first LMC being added to this site, it will become the root controller for any future devices or groups added here.',
              )}
            </div>
          )}
          <div className="flex items-center gap-6 rounded-2xl bg-gray-100 p-4">
            <img
              src={brandVoolIcon}
              alt="VOOL"
              className={`hidden h-12 w-12 rounded-lg border lg:block ${isCharger ? 'bg-black' : 'bg-white'}`}
            />
            <div className="font-poppins">
              <div className="text-base font-bold">{getVoolDeviceTypeLabel()}</div>
              {isCharger && (
                <div className="text-sm leading-6">
                  22kW • <img src={type2Icon} className="inline" alt="Type 2" title="Type 2" />{' '}
                  {t('typeNumber', 'Type {{type}}', { type: 2 })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </EditingSidebarBase>
  );
};

export default AddDeviceSidebar;
